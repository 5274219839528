<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        MOT
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addMOT()"
          class="
          flex
          items-center
          bg-green-200
          border
          hover:bg-green-300
          border-green-400
          hover:border-green-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add MOT</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <!-- :line-numbers="true" -->

      <vue-good-table
        v-if="mots.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="mots"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      >
        <div slot="emptystate">
          No data.
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteMOT(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'update:fleets', 'read:fleets']">
              <button
                type="button"
                @click="editMOT(props.row)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="mots.length == 0">
        There are no MOTs for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel :showing="addEditMOTPanelOpen" @close="addEditMOTPanelOpen = false" :title="editingMOT.mot_id ? 'Edit MOT' : 'Add MOT'">
        <AddEditMOT :mot="editingMOT" @complete="saveMOT" @delete="deleteMOT" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message" @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditMOT = () => import("@/components/Fleets/Edit/AddEditMOT.vue");
import dateRangeOverlap from "@/utils/dateRangeOverlap";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "MOT",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditMOT,
    IsAuthorized,
  },
  props: {
    mots: Array,
  },
  data() {
    return {
      addEditMOTPanelOpen: false,
      editingMOT: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Test Date",
          field: "test_date",
          width: "15%",
          // type: "date",
          // tdClass: this.tblClassLeftAlign,
          // thClass: this.tblClassLeftAlign,
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
        },
        {
          label: "Expiry Date",
          field: "expiry_date",
          width: "15%",
          // type: "date",
          // tdClass: this.tblClassLeftAlign,
          // thClass: this.tblClassLeftAlign,
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
        },
        {
          label: "Test Number",
          field: "test_number",
          width: "15%",
        },
        {
          label: "Mileage",
          field: "mileage_on_test",
          width: "15%",
        },
        {
          label: "Result",
          field: "result",
          width: "20%",
        },
        {
          label: "Status",
          field: "is_active",
          width: "10%",
          tdClass: this.tblClassCenterAlign,
          thClass: this.tblClassCenterAlign,
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function() {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    addMOT: function() {
      this.editingMOT = {};
      this.addEditMOTPanelOpen = true;
    },
    handleEditMOT: function(selectedRow) {
      let motData = selectedRow.row;
      this.editMOT(motData);
      /*this.$router.push({
              name: "AdEditMOT",
              params: { mot: motData },
            });*/
    },
    editMOT: function(mot) {
      this.editingMOT = { ...mot };
      this.addEditMOTPanelOpen = true;
    },
    saveMOT: async function(mot) {
      // Ensure mot has mandatory fields filled out
      let msg;
      if (!mot.test_number) {
        msg = "Please enter test no.";
      } else if (!mot.test_date) {
        msg = "Please provide test date";
      } else if (!mot.expiry_date) {
        msg = "Please provide expiry date";
      } else if (!mot.mileage_on_test) {
        msg = "Please provide mileage";
      } else if (!mot.reminder_date) {
        msg = "Please provide reminder date";
      }

      // else if (
      //   this.$validator.isBefore(
      //     mot.expiry_date instanceof Date ? mot.expiry_date.toDateString() : mot.expiry_date,
      //     mot.test_date instanceof Date ? mot.test_date.toDateString() : mot.test_date
      //   )
      // ) {
      //   msg = "MOT test date must be less than expiry date.";
      // }

      // this.mots.forEach(function(item) {
      //   if (item.mot_id != mot.mot_id) {
      //     if (dateRangeOverlap(new Date(item.test_date), new Date(item.expiry_date), mot.test_date, mot.expiry_date)) {
      //       msg = "Date overlap with other MOTs.";
      //     }
      //   }
      // });

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditMOTPanelOpen = false;

      try {
        mot.is_active = true;
        if (mot.expiry_date) {
          mot.is_active = new Date(mot.expiry_date) >= new Date();
        }
        if (!mot.mot_id) {
          let createdResult = await this.FleetService.createFleetMOT(this.fleet_id, mot);
          mot.mot_id = createdResult.data.mot_id;
          this.mots.push(mot);
        } else {
          await this.FleetService.updateFleetMOT(this.fleet_id, mot.mot_id, mot);
          let idx = this.$_.findIndex(this.mots, (c) => c.mot_id == mot.mot_id);
          this.mots.splice(idx, 1, mot);
        }

        this.$emit("complete");
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "MOT details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !mot.mot_id ? "There was a problem creating the new MOT" : "There was a problem updating the MOT";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the MOT"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteMOTToCurrent: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_active = true;
          await this.saveMOT(this.confirmAlert.data);
          this.mots.forEach((c) => {
            if (c.mot_id !== this.confirmAlert.data.mot_id) {
              c.is_active = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the MOT"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteMOTToCurrent: function(mot) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteMOTToCurrent,
        message: `Change '${mot.test_number}' as current mot?`,
        showing: true,
        data: mot,
      };
    },
    handleDeleteMOT: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteMOT(this.fleet_id, this.confirmAlert.data.mot_id);
          let idx = this.$_.findIndex(this.mots, (c) => {
            return c.mot_id == this.confirmAlert.data.mot_id;
          });

          this.$delete(this.mots, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "MOT Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted mot"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the MOT"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
        this.addEditMOTPanelOpen = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteMOT: function(mot) {
      // If we're deleting a mot that hasn't been saved yet
      if (String(mot.mot_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.mots, (c) => {
          return c.mot_id == mot.mot_id;
        });

        this.$delete(this.mots, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteMOT,
        message: `Are you sure you wish to delete '${mot.test_number}' This cannot be undone.`,
        showing: true,
        data: mot,
      };
    },
  },
};
</script>
